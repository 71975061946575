import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { deleteCookie } from '../../api';
import { logoutFromUser } from '../../api/auth';

import { logoutUser, setShowLoginModalOpen } from '../../redux/auth/actions';
import { toggleSidebar } from '../../redux/navbar/actions';
import { setSearchText } from '../../redux/documentController/actions';
import { avatarBackgroundColor } from '../../static/index';

import { LIBRARY_URL } from '../../static/config';
import { isMobileBrowser, PageDetectionMethod } from '../../utils';

import Modal from '../../components/custom/Modal';
import EnterToApp from '../../components/EnterToApp';
import Avatar from '../../components/Avatar';
import AppStoreLink from '../../components/AppStoreLink';
import GoogleStoreLink from '../../components/GoogleStoreLink';

import { ReactComponent as ReminderIcon } from '../../assets/img/reminder_grey_18x18.svg';
import { ReactComponent as QuestionIcon } from '../../assets/img/question-2.svg';
import { ReactComponent as LogoutIcon } from '../../assets/img/log-out.svg';
import { ReactComponent as ArrowExitIcon } from '../../assets/img/arrow-exit.svg';
import { ReactComponent as SettingsIcon } from '../../assets/img/setings.svg';
import { ReactComponent as ArrowIcon } from '../../assets/img/arrow-right-2.svg';
import { ReactComponent as CheckIcon } from '../../assets/img/new_icons/status-signed_18x18.svg';
import { ReactComponent as VoidedIcon } from '../../assets/img/new_icons/voided_grey_18x18.svg';
import { ReactComponent as VoidedRedIcon } from '../../assets/img/new_icons/voided-red_18x18.svg';
import { ReactComponent as EyeIcon } from '../../assets/img/new_icons/eye_yellow_18x18.svg';
import { ReactComponent as ReminderGreyIcon } from '../../assets/img/new_icons/reminder_grey_16x18.svg';
import {
  getTokenFromCookie,
  getTokenFromCookieAdmin,
  setAuthorizationAdmin,
} from '../../api';
import { getDataSessionStorage, setDataToSessionStorage } from '../../utils';
import './style.scss';
import { Notifications } from './mock';
import HeaderSearchBar from './HeaderSearchBar';

class TopNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotif: false,
      emptyNotif: false,
      emailVerifiedModal: false,
      enterToAppTemplateType: '',
      showSubNav: false,
      showSupNav: false,
      showAccountsLoader: false,
      switchAccountsList: [],
      searchQuery: '',
      showStickyHeaderClassName: 'false',
      searchBarFocus: false,
    };

    this.subNavRef = React.createRef();
    this.supNavRef = React.createRef();
    this.notifMenuRef = React.createRef();
  }

  componentDidMount() {
    const { location } = this.props;

    if (location.pathname === '/email-verified') {
      this.setState({ emailVerifiedModal: true });
    }

    this.onScrollDetectionHandler();

    document.addEventListener('mousedown', this.handleClickOutsideSubNav);
    document.addEventListener('scroll', this.onScrollDetectionHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideSubNav);
    document.removeEventListener('scroll', this.onScrollDetectionHandler);
  }

  toggleEmailVerifiedModal = () => {
    const { emailVerifiedModal } = this.state;

    this.props.history.push('/');
    this.setState({ emailVerifiedModal: !emailVerifiedModal });
  };

  handleEmailVerifiedSubmitClick = () => {
    this.toggleEmailVerifiedModal();
    this.openSignInModal();
  };

  openSignInModal = () => {
    this.setState({ enterToAppTemplateType: 'SIGN_IN' });
  };

  closeEnterToApp = () => {
    this.setState({ enterToAppTemplateType: '' });
  };

  handleClickOutsideSubNav = e => {
    if (this.state.showSubNav) {
      if (!this.subNavRef?.current?.contains(e?.target)) {
        this.setState({
          showSubNav: false,
        });
      }
    }

    if (this.state.showSupNav) {
      if (!this.supNavRef?.current?.contains(e?.target)) {
        this.setState({
          showSupNav: false,
        });
      }
    }

    if (this.state.showNotif) {
      if (!this.notifMenuRef?.current?.contains(e?.target)) {
        this.setState({
          showNotif: false,
        });
      }
    }
  };

  myDocumentsHandler = () => {
    this.props.history.push('/documents');

    if (this.props.showSidebar) {
      this.props.toggleSidebar();
    }
  };

  searchKeyPressHandler = e => {
    if (e.key === 'Enter') {
      this.doSearch();
    }
  };

  searchChangeHandler = e => {
    this.setState({ searchQuery: e.target.value });
  };

  doSearch = () => {
    const { searchQuery } = this.state;
    if (searchQuery.trim() !== '') {
      window.location = `${LIBRARY_URL}/?s=${searchQuery}`;
    }
  };

  logoutUser = () => {
    this.setState({ showSubNav: false });

    logoutFromUser();

    deleteCookie();
    this.props.logoutUser();
    this.props.history.push('/');
  };

  isSearchDocumentActive = () =>
    PageDetectionMethod.Documents() ||
    PageDetectionMethod.Shared() ||
    PageDetectionMethod.Starred() ||
    PageDetectionMethod.Trash();

  onScrollDetectionHandler = () => {
    if (!isMobileBrowser()) {
      this.setState({
        showSubNav: false,
        supNavRef: false,
      });

      if (window.scrollY === 0 || window.scrollY <= 62) {
        this.setState({
          showStickyHeaderClassName: 'false',
        });
      } else if (window.scrollY >= 100) {
        this.setState({
          showStickyHeaderClassName: 'is-sticky--open',
        });
      } else if (
        window.scrollY <= 96 &&
        window.scrollY >= 62 &&
        this.state.showStickyHeaderClassName === 'is-sticky--open'
      ) {
        this.setState({
          showStickyHeaderClassName: 'is-sticky--close',
        });
      }
    }
  };

  onSearchBarFocus = searchBarFocus => {
    this.setState({
      searchBarFocus,
    });
  };

  iconType = (n, i) => {
    if (n.type === 'completed') {
      return (
        <div className="icon icon-check">
          <CheckIcon />
        </div>
      );
    } else if (
      n.type === 'viewed' ||
      n.type === 'signed' ||
      n.type === 'voided-user'
    ) {
      return (
        <div className="icon icon-profile">
          <span>
            <Avatar
              className={'avatar'}
              fullName={n?.name}
              bacgroundColor={i !== 0 && avatarBackgroundColor[i - 1]}
              logoUrl={''}
              showPreviewImgByClick={true}
            />
            <i className="icon-check-svg">
              {n.type === 'viewed' ? (
                <EyeIcon />
              ) : n.type === 'voided-user' ? (
                <VoidedRedIcon />
              ) : (
                <CheckIcon />
              )}
            </i>
          </span>
        </div>
      );
    } else if (i === 4) {
      return (
        <div className="icon icon-check">
          <VoidedIcon />
        </div>
      );
    }
  };

  exitAdminMode = (token, activeToken) => {
    setDataToSessionStorage(
      {
        impersonate: false,
        userId: activeToken,
      },
      'impersonate',
    );
    setAuthorizationAdmin(token);
    window.location.href = `/management`;
  };

  render() {
    const { user, notification, showLoginModal } = this.props;
    const { emailVerifiedModal } = this.state;
    const { enterToAppTemplateType } = this.state;
    const {
      location: { pathname },
    } = this.props;

    const activePricing = pathname.includes('pricing');
    const activeToken = getTokenFromCookie();
    const dataFromStorage = getDataSessionStorage(activeToken, 'impersonate');
    const adminToken = getTokenFromCookieAdmin();

    return (
      <>
        <div
          className={`top-nav ${
            this.props?.isAlmostThere ? `top-nav-almost-there` : ``
          } ${this.props.isHomePage && 'home'} ${isMobileBrowser() &&
            'is-mobile'} ${this.state.showStickyHeaderClassName}`}
        >
          <div className="main-top-container">
            {!this.state.searchBarFocus && (
              <div className="left-side-container">
                {this.props.user &&
                  !this.props.isHomePage &&
                  isMobileBrowser() && (
                    <div
                      className="burger-btn-wrapper"
                      onClick={() =>
                        isMobileBrowser() && this.props.toggleSidebar()
                      }
                    >
                      <svg
                        className={
                          this.props.showSidebar
                            ? 'burger-btn active'
                            : 'burger-btn'
                        }
                        width="30"
                        height="32"
                        viewBox="0 0 40 26"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          className="burger-btn--1"
                          width="100%"
                          height="6"
                          rx="3"
                          ry="3"
                        />
                        <rect
                          className="burger-btn--2"
                          width="100%"
                          height="6"
                          y="10"
                          rx="3"
                          ry="3"
                        />
                        <rect
                          className="burger-btn--3"
                          width="100%"
                          height="6"
                          y="20"
                          rx="3"
                          ry="3"
                        />
                      </svg>
                    </div>
                  )}
                <Link to="/" className="logo">
                  <img
                    width="105.77"
                    height="40"
                    src={
                      this.props.isHomePage ||
                      this.state.showStickyHeaderClassName === 'is-sticky--open'
                        ? '/imgs/esign-logo.svg'
                        : '/imgs/esign-logo-black.svg'
                    }
                    alt="eSign logo"
                  />
                  <p
                    style={{
                      color:
                        this.props.isHomePage ||
                        this.state.showStickyHeaderClassName ===
                          'is-sticky--open'
                          ? 'white'
                          : 'black',
                    }}
                  >
                    TM
                  </p>
                </Link>
              </div>
            )}
            {(PageDetectionMethod.Documents() &&
              !PageDetectionMethod.Preview() &&
              !PageDetectionMethod.Editor()) ||
            PageDetectionMethod.Shared() ||
            PageDetectionMethod.Starred() ||
            PageDetectionMethod.Templates() ||
            PageDetectionMethod.Inbox() ||
            PageDetectionMethod.Sent() ||
            PageDetectionMethod.Trash() ? (
              <HeaderSearchBar
                onSearchBarFocus={this.onSearchBarFocus.bind(this)}
                isFocused={this.state.searchBarFocus}
              />
            ) : null}
            <div className="top-nav__items">
              {user && notification?.browserPush && (
                <>
                  <div className="top-nav__items_reminder">
                    <span
                      onClick={() => {
                        this.setState({ showNotif: !this.state.showNotif });
                      }}
                      className="top-nav__items_reminder_count"
                    >
                      10
                    </span>
                    <ReminderIcon
                      onClick={() => {
                        this.setState({ showNotif: !this.state.showNotif });
                      }}
                    />
                    {this.state.showNotif && (
                      <div
                        ref={this.notifMenuRef}
                        className={`${
                          this.state.emptyNotif
                            ? `top-nav__items_reminder_notif_list top-nav__items_reminder_notif_list_empty_wrapper`
                            : `top-nav__items_reminder_notif_list`
                        }`}
                      >
                        <div className="top-nav__items_reminder_notif_list_item_title">
                          <b>Notifications</b>
                          <span
                            onClick={() => {
                              this.props.history.push('/settings/preferences');
                            }}
                            className="settings-notif"
                          >
                            <SettingsIcon />
                          </span>
                        </div>
                        <div
                          className={`top-nav__items_reminder_notif_list_wrapper ${
                            this.state.emptyNotif ? `empty` : ``
                          }`}
                        >
                          {this.state.emptyNotif ? (
                            <>
                              <div className="top-nav__items_reminder_notif_list_empty">
                                <ReminderGreyIcon />
                                <span>You have no new notifications.</span>
                              </div>
                            </>
                          ) : (
                            <>
                              {Notifications.map((n, i) => {
                                return (
                                  <div className="top-nav__items_reminder_notif_list_item">
                                    <span className="time">1 hour ago</span>
                                    <div className="top-nav__items_reminder_notif_list_item_description">
                                      {this.iconType(n, i)}
                                      <div>
                                        <b className="title">{n.description}</b>
                                        <span className="sub-title">
                                          {n.file}
                                        </span>
                                      </div>
                                      <div className="arrow">
                                        <ArrowIcon />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                        <div
                          className={`top-nav__items_reminder_notif_list_item_footer ${
                            this.state.emptyNotif
                              ? `top-nav__items_reminder_notif_list_item_footer_read`
                              : ``
                          }`}
                        >
                          <b
                            onClick={() => {
                              this.setState({ emptyNotif: true });
                            }}
                          >
                            Mark all as read
                          </b>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              {user ? (
                this.props.isHomePage ? (
                  <button
                    onClick={this.myDocumentsHandler}
                    style={{ display: this.props.isHomePage && 'block' }}
                    className="top-nav__item"
                  >
                    My documents
                  </button>
                ) : (
                  <div ref={this.supNavRef}>
                    <button
                      onClick={() =>
                        this.setState({ showSupNav: !this.state.showSupNav })
                      }
                      className="top-nav__item"
                    >
                      <QuestionIcon />
                    </button>
                    {this.state.showSupNav && (
                      <div className="sup-nav">
                        <div className="sup-nav__actions">
                          <a href={`${LIBRARY_URL}/support`}>Support</a>
                          <a href={`${LIBRARY_URL}/terms-of-use/`}>Terms</a>
                          <button
                            onClick={() => {
                              this.setState(
                                {
                                  showSupNav: false,
                                },
                                () =>
                                  this.props.history.push(
                                    user
                                      ? '/settings/billing/plans'
                                      : '/pricing',
                                  ),
                              );
                            }}
                          >
                            Pricing
                          </button>
                          <div className="sup-nav__actions--app-icons">
                            <AppStoreLink />
                            <GoogleStoreLink />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              ) : (
                <>
                  {activePricing && (
                    <button
                      className="esign-button esign-button--light esign-button--login"
                      onClick={() => {
                        this.props.setShowLoginModalOpen(true);
                      }}
                    >
                      Login / Sign up
                    </button>
                  )}
                </>
              )}
              {user ? (
                <div ref={this.subNavRef}>
                  <div
                    onClick={() =>
                      this.setState({ showSubNav: !this.state.showSubNav })
                    }
                  >
                    <Avatar
                      className={'user-avatar'}
                      fullName={this.props.user?.fullName}
                      logoUrl={this.props.user?.logoUrl}
                      bacgroundColor={this.props.user?.profileColor}
                      showPreviewImgByClick={false}
                    />
                  </div>
                  {this.state.showSubNav && (
                    <div className="sub-nav">
                      <div className="sub-nav__header">
                        <div className="sub-nav__header--info">
                          <strong className="name">{user.fullName}</strong>
                          <span className="email">{user.email}</span>
                        </div>
                        <Avatar
                          className={'sub-nav__header--avatar'}
                          fullName={this.props.user?.fullName}
                          logoUrl={this.props.user?.logoUrl}
                          bacgroundColor={this.props.user?.profileColor}
                          showPreviewImgByClick={false}
                        />
                      </div>
                      <div className="sub-nav__actions">
                        {!this.props.user.originalUser && (
                          <button
                            onClick={() => {
                              this.setState({ showSubNav: false });
                              this.props.history.push('/settings');
                            }}
                          >
                            <SettingsIcon /> Settings
                          </button>
                        )}
                        <button onClick={() => this.logoutUser()}>
                          <LogoutIcon /> Sign out
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                !window.location.pathname.includes('/pricing') && (
                  <button
                    onClick={this.openSignInModal}
                    className="esign-button esign-button--link"
                    style={{
                      color: PageDetectionMethod.Pricing() ? 'black' : 'white',
                    }}
                  >
                    Log in / Sign up
                  </button>
                )
              )}
            </div>
          </div>

          {enterToAppTemplateType && (
            <EnterToApp
              initTemplate={enterToAppTemplateType}
              emitCloseModal={this.closeEnterToApp}
            />
          )}

          {emailVerifiedModal && (
            <Modal toggle={this.toggleEmailVerifiedModal}>
              <div className="esign-modal esign-modal--narrow">
                <h3 className="esign-modal__title esign-modal__title--centered">
                  Thank you! Your account has been verified. Now you can sign in
                </h3>
                <div className="esign-modal__footer esign-modal__footer--centered">
                  <button
                    onClick={this.handleEmailVerifiedSubmitClick}
                    className="esign-button"
                  >
                    OK
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </div>
        {dataFromStorage?.impersonate && (
          <div className="impersonate">
            <button
              onClick={() => {
                this.exitAdminMode(adminToken, activeToken);
              }}
            >
              <ArrowExitIcon />
              EXIT
            </button>
            YOU ARE CURRENTLY LOGGED-IN TO: {user?.fullName} ({user?.email})
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ authUser, navBar, documentController }) => {
  const { user, notification, showLoginModal } = authUser;
  const { deviceWidth, showSidebar } = navBar;
  const { searchText } = documentController;

  return {
    user,
    deviceWidth,
    showSidebar,
    searchText,
    notification,
    showLoginModal,
  };
};

const mapDispatchToProps = {
  logoutUser,
  toggleSidebar,
  setSearchText,
  setShowLoginModalOpen,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNav));
