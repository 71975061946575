import React, { useRef, forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import cn from 'classnames';
import { v4 as uuidV4 } from 'uuid';
import { useLocation } from 'react-router-dom';

import { searchContact as getAllContact, getContact } from '../../api/contact';
import CustomInput from '../custom/CustomInput';
import ContactInput from '../custom/ContactInput';

import './style.scss';
import { generateRandomColor } from '../../utils';
import { DragHandle } from '../SignerFormV2/DragHandle';

import { RECIPIENT_COLOR } from '../SigningProcessV2/constants';
import { RECIPIENTS_TYPE, RECIPIENTS_TYPE_LABEL } from '../../static';
import { ROLE_DEFAULT } from '../TemplateFlow/RolesEdit';

import { ReactComponent as CloseIcon } from '../../assets/img/close.svg';
import { ReactComponent as AddIcon } from '../../assets/img/new_icons/plus-circle.svg';
import { ReactComponent as PrefillIcon } from '../../assets/img/user-send_18x18.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/img/arrow-turn-right_25x32.svg';
import { ReactComponent as AddressIcon } from '../../assets/img/address_16x18.svg';
import { ReactComponent as ErrorIcon } from '../../assets/img/exclamation-mark.svg';

const RolesForm = props => {
  const location = useLocation();
  const typeTemplate = new URLSearchParams(location.search).get('type');
  const [selected, setSelected] = useState({});
  const [deletedRole, setDeletedRole] = useState({});

  const getActiveName = (selected, options) => {
    return options.find(f => f.value === selected)?.name;
  };

  const getActiveEmail = (selected, options) => {
    return options.find(f => f.value === selected)?.email;
  };

  const onRoleNameChange = (e, ind) => {
    const { onRoleNameChange } = props;
    onRoleNameChange(e.target.value, ind);
  };

  const onPrefillEmailChange = (e, ind) => {
    const { onPrefillEmailChange } = props;
    onPrefillEmailChange(e.target.value, ind);
  };

  const onPrefillNameChange = (e, ind, type) => {
    const { onPrefillNameChange } = props;
    if (type === 'autocomplete') {
      const value = getActiveName(selected[ind], props.contacts);
      if (value !== e.target.value) {
        const newData = selected;
        delete newData[ind];
        setSelected(newData);
      }
      onPrefillNameChange(e.target.value, ind);
      return;
    }
    onPrefillNameChange(e.target.value, ind);
  };

  const addRoleHandler = () => {
    const { addRole } = props;
    addRole({
      role: uuidV4(),
      ...ROLE_DEFAULT,
      isContactNameTouched: false,
      new: props.afterSigning ? true : false,
      hasPrefill: props.afterSigning ? true : false,
    });
  };

  const removeRoleHandler = (role, index) => {
    setDeletedRole({ ...role, index });
    props.removeRoleConfirm(role, index).then(res => {
      if (!res) {
        const { removeRole } = props;
        removeRole(index);
        const updateColors = props.colors.current;
        updateColors.splice(index, 1);
        props.setColors(updateColors);
        setDeletedRole({});
      }
    });
  };

  const getColor = (index, recipientType) => {
    const updateColors = props.colors.current;
    if (index > updateColors.length - 1) {
      const newColor =
        recipientType === RECIPIENTS_TYPE.CC
          ? RECIPIENT_COLOR
          : generateRandomColor();
      updateColors.push(newColor);
      props.setColors(updateColors);
    } else {
      if (
        recipientType === RECIPIENTS_TYPE.CC &&
        updateColors[index] !== RECIPIENT_COLOR
      ) {
        updateColors[index] = RECIPIENT_COLOR;
        props.setColors(updateColors);
      }
    }

    return updateColors[index];
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (!props.signingOrder) {
      props.setOrdered(true);
    }
    const items = reorder(
      props.roles,
      result.source.index,
      result.destination.index,
    );

    if (
      props.roles[result.source.index]?.type === RECIPIENTS_TYPE.CC ||
      props.roles[result.destination.index]?.type === RECIPIENTS_TYPE.CC
    ) {
      const colors = reorder(
        props.colors.current,
        result.source.index,
        result.destination.index,
      );
      props.setColors(colors);
    }

    props.updateRoles(items);
  };

  const onKeyPressHandler = e => {
    if (e.key === 'Enter') {
      props.onEnterKeyPress();
    }
  };

  const onChangeRecipientsType = (selectedRole, type, roleIdx) => {
    const updateColors = props.colors.current;
    if (roleIdx > updateColors.length - 1) {
      const newColor =
        type === RECIPIENTS_TYPE.CC ? RECIPIENT_COLOR : generateRandomColor();
      updateColors.push(newColor);
    } else {
      updateColors[roleIdx] =
        type === RECIPIENTS_TYPE.CC ? RECIPIENT_COLOR : generateRandomColor();
    }
    props.setColors(updateColors);
    const updated = props.roles.map(role => {
      if (role.role === selectedRole.role) {
        return { ...role, type };
      }
      return role;
    });
    props.updateRoles(updated);
  };

  const getItemStyle = (isDragging, draggableStyle, idx) => ({
    userSelect: 'none',
    boxShadow: isDragging ? '0px 5px 10px 2px rgba(0,0,0,0.1)' : 'none',
    ...draggableStyle,
  });

  const togglePrefill = selectedRole => {
    const updated = props.roles.map(role => {
      if (role.role === selectedRole.role) {
        return Object.keys(role).includes('new')
          ? {
              ...role,
              hasPrefill: !role.hasPrefill,
              new: !role.hasPrefill,
            }
          : {
              ...role,
              hasPrefill: !role.hasPrefill,
            };
      }
      return role;
    });
    props.updateRoles(updated);
  };

  const getActiveContact = () => {
    if (props?.user) {
      const params = new URLSearchParams({
        order: 'desc',
        query: '',
        sortBy: 'createdAt',
      }).toString();

      getAllContact(params)
        .then(data => {
          const newContacts = data.contacts.map(c => {
            return {
              ...c,
              name: c.fullName,
              value: c.id,
            };
          });
          props.setLoadingContacts(false);
          props.setContacts(newContacts);
        })
        .catch(() => {
          props.setLoadingContacts(false);
          props.setContacts([]);
        });
    }
  };

  const handleEmailEvent = (e, ind, isBlur = false) => {
    const value = e.target.value;
    let shouldSetOrdered = false;

    if (isBlur) {
      const mailExist = props.roles.filter(a => a.prefillEmail === value)
        .length;
      shouldSetOrdered = mailExist > 1 && value;
    } else {
      const mailExist = props.roles.filter(a => a.prefillEmail === value);
      shouldSetOrdered =
        mailExist.length > 0 && value === mailExist[0]?.prefillEmail;
    }

    if (shouldSetOrdered) {
      props.setOrdered(true);
    }

    onPrefillEmailChange(e, ind);
  };

  const options = props?.contacts?.filter(
    t => !Object.values(selected).includes(t.value),
  );

  const autoFillContacts =
    typeof props?.user?.settings?.contacts?.autoFillContacts !== 'undefined'
      ? !!props?.user?.settings?.contacts?.autoFillContacts
      : true;

  useEffect(() => {
    if (props.updatedRemoved && Object.keys(deletedRole).length > 0) {
      const { removeRole } = props;
      removeRole(deletedRole.index);
      const updateColors = props.colors.current;
      updateColors.splice(deletedRole.index, 1);
      props.setColors(updateColors);
      setDeletedRole({});
    }

    if (autoFillContacts) {
      getActiveContact();
    }
  }, [props.updatedRemoved, deletedRole, autoFillContacts]);

  useEffect(() => {
    if (
      props?.activeRoleContact &&
      props?.roleContact &&
      Object.keys(props?.roleContact).length > 0
    ) {
    }
  }, [props.activeRoleContact, props?.roleContact]);

  return (
    <>
      {props?.showErrorToggle && (
        <>
          <span className="esign-input__error-msg esign-input__error-msg-custom">
            <ErrorIcon />
            Cannot have duplicate recipients unless the signing order is toggled
            ON
          </span>
        </>
      )}
      <div className="roles-form">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {props.roles.map((role, ind) => {
                  const activeBackground = role.color
                    ? role.color
                    : getColor(ind, role.type);

                  return (
                    <Draggable
                      key={role.role}
                      draggableId={role.role}
                      index={ind}
                      isDragDisabled={props.isNotarizing}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={cn('roles-form__add-role-block', {
                            'roles-form__add-role-block--error':
                              role.isEmailTouched &&
                              !role.isNameValid &&
                              role.isNameTouched &&
                              !role.isEmailValid,
                          })}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            ind,
                          )}
                        >
                          <div
                            id={role.role}
                            data-background={activeBackground}
                            className={'roles-form__add-role-block--color-tag'}
                            style={{
                              backgroundColor: activeBackground,
                            }}
                          />

                          {props?.signingOrder && (
                            <span
                              className={'signers-order'}
                              style={{ borderColor: activeBackground }}
                            >
                              {ind + 1}
                            </span>
                          )}

                          <span className="roles-form__title">
                            <select
                              tabIndex={-1}
                              className={
                                'roles-form__add-role--type selectable'
                              }
                              onChange={e =>
                                onChangeRecipientsType(
                                  role,
                                  e.target.value,
                                  ind,
                                )
                              }
                            >
                              {Object.keys(RECIPIENTS_TYPE)
                                .filter(key => {
                                  if (
                                    typeTemplate === 'template_link' &&
                                    RECIPIENTS_TYPE[key] !== 'cc'
                                  ) {
                                    return key;
                                  } else if (typeTemplate !== 'template_link') {
                                    return key;
                                  }
                                })
                                .map(key => (
                                  <option
                                    value={RECIPIENTS_TYPE[key]}
                                    key={key}
                                    selected={
                                      RECIPIENTS_TYPE[key] === role?.type
                                    }
                                  >
                                    {props.afterSigning &&
                                    role?.roleName &&
                                    !role?.new
                                      ? role?.roleName +
                                        ` (${
                                          RECIPIENTS_TYPE_LABEL[
                                            RECIPIENTS_TYPE[key]
                                          ]
                                        })`
                                      : RECIPIENTS_TYPE_LABEL[
                                          RECIPIENTS_TYPE[key]
                                        ]}
                                  </option>
                                ))}
                            </select>
                          </span>
                          <div className={'roles-form__add-role--content'}>
                            <DragHandle {...provided.dragHandleProps} />
                            <div className={'roles-form__add-role--form-group'}>
                              {((props.afterSigning && !role?.roleName) ||
                                !props.afterSigning ||
                                role.new) && (
                                <div className="input-field-wrapper">
                                  <CustomInput
                                    wrapperClassName={'roles-form__role-input'}
                                    value={role.roleName}
                                    type="text"
                                    disabled={role.isDisabled}
                                    autoFocus={props.roles.length - 1 === ind}
                                    placeholder={
                                      'Role (e.g., Landlord, Employee)'
                                    }
                                    name="roleName"
                                    onChange={e => onRoleNameChange(e, ind)}
                                    onBlur={e => onRoleNameChange(e, ind)}
                                    onKeyPress={onKeyPressHandler}
                                    error={
                                      props.showError &&
                                      (!role.isRoleNameValid ||
                                        role.roleName?.length > 50)
                                    }
                                    valid={role.isRoleNameValid}
                                    errorMessage={
                                      role.roleName?.length > 50
                                        ? 'The role name shouldn’t be longer than 50 characters.'
                                        : `Please enter recipient's role`
                                    }
                                  >
                                    <button
                                      type={'button'}
                                      className={cn('toggle-prefill-button', {
                                        active: role.hasPrefill,
                                      })}
                                      style={{
                                        cursor: props.afterSigning
                                          ? `auto`
                                          : `pointer`,
                                      }}
                                      onClick={() => {
                                        if (props.afterSigning) {
                                          return;
                                        }
                                        togglePrefill(role, ind);
                                      }}
                                    >
                                      <PrefillIcon />
                                    </button>
                                  </CustomInput>
                                </div>
                              )}
                              {(role.hasPrefill || props.afterSigning) && (
                                <div className={'prefill-wrapper'}>
                                  {/* for templates */}
                                  {!props.afterSigning && <ArrowRightIcon />}
                                  <div className={'prefill-form-group'}>
                                    <div className="input-field-wrapper">
                                      {autoFillContacts ? (
                                        <ContactInput
                                          type="text"
                                          placeholder={
                                            props.afterSigning
                                              ? 'Full Name'
                                              : 'Prefill Name'
                                          }
                                          autocomplete={
                                            role.isContactNameTouched && true
                                          }
                                          onFocus={() => {
                                            props.updateRoles(
                                              props.roles.map(r =>
                                                r.role === role.role
                                                  ? {
                                                      ...r,
                                                      isContactNameTouched: true,
                                                      color: activeBackground,
                                                    }
                                                  : { ...r },
                                              ),
                                            );
                                          }}
                                          selected={selected[ind]}
                                          onSelected={value => {
                                            setSelected(prev => ({
                                              ...prev,
                                              [ind]: value,
                                            }));
                                            const {
                                              onPrefillNameEmailChange,
                                            } = props;
                                            const activeName = getActiveName(
                                              value,
                                              props.contacts,
                                            );
                                            const activeEmail = getActiveEmail(
                                              value,
                                              props.contacts,
                                            );
                                            onPrefillNameEmailChange(
                                              activeName,
                                              activeEmail,
                                              ind,
                                            );
                                          }}
                                          options={options}
                                          value={role.prefillName}
                                          valid={role.isPrefillNameValid}
                                          onChange={e =>
                                            onPrefillNameChange(
                                              e,
                                              ind,
                                              'autocomplete',
                                            )
                                          }
                                          onBlur={e =>
                                            onPrefillNameChange(
                                              e,
                                              ind,
                                              'autocomplete',
                                            )
                                          }
                                          error={
                                            props.showError &&
                                            role.hasPrefill &&
                                            (!role.isPrefillNameValid ||
                                              role.prefillName?.length > 50)
                                          }
                                          errorMessage={
                                            role.prefillName?.length > 50
                                              ? 'The name shouldn’t be longer than 50 characters.'
                                              : `Please enter recipient's first and last name`
                                          }
                                        />
                                      ) : (
                                        <CustomInput
                                          value={role.prefillName}
                                          type="text"
                                          disabled={role.isDisabled}
                                          autoFocus={true}
                                          placeholder={
                                            props.afterSigning
                                              ? 'Full Name'
                                              : 'Prefill Name'
                                          }
                                          name="prefillName"
                                          onChange={e =>
                                            onPrefillNameChange(
                                              e,
                                              ind,
                                              'static',
                                            )
                                          }
                                          onBlur={e =>
                                            onPrefillNameChange(
                                              e,
                                              ind,
                                              'static',
                                            )
                                          }
                                          onKeyPress={onKeyPressHandler}
                                          error={
                                            props.showError &&
                                            role.hasPrefill &&
                                            (!role.isPrefillNameValid ||
                                              role.prefillName?.length > 50)
                                          }
                                          valid={role.isPrefillNameValid}
                                          errorMessage={
                                            role.prefillName?.length > 50
                                              ? 'The name shouldn’t be longer than 50 characters.'
                                              : `Please enter recipient's first and last name`
                                          }
                                        />
                                      )}
                                      {props?.user ? (
                                        <span
                                          onClick={() => {
                                            props.setRoles(props.roles);
                                            props.setRoleContact({
                                              ...role,
                                              color: activeBackground,
                                            });
                                            props.toggleNewTemplateModal(false);
                                            props.toggleShowContactModal(true);
                                            getActiveContact();
                                          }}
                                          className="contact-action"
                                        >
                                          <AddressIcon />
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="input-field-wrapper">
                                      <CustomInput
                                        value={role.prefillEmail}
                                        type="email"
                                        disabled={role.isDisabled}
                                        placeholder={
                                          props.afterSigning
                                            ? 'Email'
                                            : 'Prefill Email'
                                        }
                                        name="prefillEmail"
                                        onChange={e => {
                                          handleEmailEvent(e, ind, false);
                                        }}
                                        onBlur={e => {
                                          handleEmailEvent(e, ind, true);
                                        }}
                                        onKeyPress={onKeyPressHandler}
                                        error={
                                          props.showError &&
                                          !role.isPrefillEmailValid
                                        }
                                        valid={role?.isPrefillEmailValid}
                                        errorMessage="Please enter a valid email address"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {!role.isDisabled && ind > 0 && (
                            <button
                              className="remove-role-btn"
                              onClick={() => removeRoleHandler(role, ind)}
                              tabIndex={-1}
                            >
                              <CloseIcon />
                            </button>
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div>
          {typeTemplate !== 'template_link' && (
            <button
              className="add-role-btn"
              onClick={addRoleHandler}
              tabIndex={0}
            >
              <AddIcon />
              {props.afterSigning ? <>Add Recipients</> : <>Add Role</>}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

RolesForm.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      roleName: PropTypes.string,
      prefillName: PropTypes.string,
      prefillEmail: PropTypes.string,
      isRoleNameValid: PropTypes.bool,
      isPrefillNameValid: PropTypes.bool,
      isPrefillEmailValid: PropTypes.bool,
      isPrefillEmailTouched: PropTypes.bool,
      isPrefillNameTouched: PropTypes.bool,
      isRoleNameTouched: PropTypes.bool,
      isDisabled: PropTypes.bool,
      hasPrefill: PropTypes.bool,
      type: PropTypes.string,
      order: PropTypes.number,
      role: PropTypes.string,
    }),
  ),
  signingOrder: PropTypes.bool,
  addRole: PropTypes.func,
  updateRoles: PropTypes.func,
  setRoles: PropTypes.func,
  removeRole: PropTypes.func,
  onRoleNameChange: PropTypes.func,
  onPrefillNameChange: PropTypes.func,
  onPrefillEmailChange: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
  showErrorMessage: PropTypes.bool,
};

RolesForm.defaultProps = {
  roles: [],
  showErrorMessage: false,
};

const mapStateToProps = ({ authUser, createTemplate }) => {
  const { user } = authUser;
  const { activeRoleContact, roleContact } = createTemplate;

  return { user, activeRoleContact, roleContact };
};

export default connect(mapStateToProps, {})(RolesForm);
