import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  loginUserSuccess,
  loginUserError,
  storageRemainingSuccess,
  setIs2FAModalOpen,
  setGlobalLoading,
} from './actions';
import { getUserInfo, loginUser } from '../../api/auth';
import {
  deleteCookie,
  setAuthorization,
  setAuthorizationAdmin,
} from '../../api';
import { getTokenFromCookie } from '../../api';
import { getDataSessionStorage } from '../../utils';
import { saveRegionalSettings } from '../../api/settings';
import {
  LOGIN_USER,
  LOGOUT_USER,
  GET_CURRENT_USER,
  GET_STORAGE_REMAINING,
  REFRESH_USER_DATA,
  LOGIN_USER_2FA,
  LOGIN_USER_EXTERNALLY,
} from '../actions';

const loginWithEmailPasswordAsync = async (email, password) =>
  await loginUser(email, password)
    .then(result => result)
    .catch(error => error);

const getCurrentUserAsync = async () =>
  await getUserInfo()
    .then(result => {
      if (
        !result.originalUser.settings.regional ||
        result.originalUser?.settings?.regional?.timeZone?.timeZone
      ) {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        saveRegionalSettings({ timeZone });
      }
      const token = getTokenFromCookie();
      const dataFromStorage = getDataSessionStorage(token, 'impersonate');
      if (result?.originalUser?.isSuperAdmin && !dataFromStorage?.impersonate) {
        setAuthorizationAdmin(token);
      }
      if (result.loggedInAsUser) {
        return {
          ...result.loggedInAsUser,
          permission: result.permission,
          originalUser: result.originalUser,
        };
      }
      return result.originalUser;
    })
    .catch(error => null);

function* service_loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const loginUser = yield call(loginWithEmailPasswordAsync, email, password);

  if (loginUser?.success) {
    yield call(saveLoggedUser, {
      user: loginUser,
      history: payload.history,
      setShowLoader: payload.setShowLoader,
    });
  } else {
    if (loginUser?.message) {
      yield put(loginUserError('Invalid email or password'));
    } else {
      yield put(
        loginUserError('Something went wrong. Please, try again later.'),
      );
    }
  }

  payload.setShowLoader(false);
}

function* loginWith2FA({ payload }) {
  const { loginUser } = payload;
  yield call(saveLoggedUser, { user: loginUser, history: payload.history });
}

function* loginExternally({ payload }) {
  const { loginUser } = payload;
  yield call(saveLoggedUser, { user: loginUser, history: payload.history });
}

function* saveLoggedUser({ user, history, setShowLoader = null }) {
  setAuthorization(user.token);

  try {
    if (user.refreshToken === null) {
      yield put(setIs2FAModalOpen(true));
    } else {
      if (user.message) {
        yield put(loginUserError('Invalid email or password'));
      } else {
        const userDetails = yield call(getCurrentUserAsync);
        yield put(loginUserSuccess(userDetails));

        if (!userDetails?.isEmailVerified) {
          history.push(`/confirm-email`);
        } else {
          history.push('/documents');
        }
      }
    }
  } catch (error) {}

  setShowLoader(false);
}

function* setUserDataAfterSwitchAccount({ payload }) {
  const userDetails = yield call(getCurrentUserAsync);
  yield put(loginUserSuccess(userDetails));
}

function* logout({}) {
  deleteCookie();
  yield put(loginUserSuccess(null));
}

function* getCurrentUser({ payload }) {
  const { history, path } = payload;
  // yield put(loginUserSuccess(null))
  try {
    const loginUser = yield call(getCurrentUserAsync);
    yield put(loginUserSuccess(loginUser));

    if (loginUser._id) {
      if (!loginUser.isEmailVerified) {
        history.push(`/confirm-email`);
      } else {
        history.push(path.pathname + path.search);
      }
    } else {
      if (!loginUser?._id) {
        yield put(loginUserSuccess(null));
        deleteCookie();
        history.push('/');
      }
    }
  } catch (error) {
    yield put(loginUserSuccess(null));
    deleteCookie();
    history.push('/');
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchCurrentUser() {
  yield takeEvery(GET_CURRENT_USER, getCurrentUser);
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, service_loginWithEmailPassword);
}

export function* watchLoginUser2FA() {
  yield takeEvery(LOGIN_USER_2FA, loginWith2FA);
}

export function* watchLoginUserExternally() {
  yield takeEvery(LOGIN_USER_EXTERNALLY, loginExternally);
}

export function* refreshUserData() {
  yield takeEvery(REFRESH_USER_DATA, setUserDataAfterSwitchAccount);
}

// export function* watchGetStorageRemaining() {
//   yield takeEvery(GET_STORAGE_REMAINING, getStorageRemainingAsync);
// }

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLoginUser2FA),
    fork(watchLoginUserExternally),
    fork(refreshUserData),
    fork(watchLogoutUser),
    fork(watchCurrentUser),
    // fork(watchGetStorageRemaining),
  ]);
}
