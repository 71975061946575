import React, { useRef, forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cn from 'classnames';

import CustomInput from '../custom/CustomInput';
import ContactInput from '../custom/ContactInput';

import { ReactComponent as CloseIcon } from '../../assets/img/close.svg';
import { ReactComponent as SignerIcon } from '../../assets/img/new_icons/pencil.svg';
import { ReactComponent as CCIcon } from '../../assets/img/new_icons/file-copy.svg';
import { ReactComponent as AddIcon } from '../../assets/img/new_icons/plus-circle.svg';
import { ReactComponent as AddressIcon } from '../../assets/img/address_16x18.svg';
import { ReactComponent as ErrorIcon } from '../../assets/img/exclamation-mark.svg';

import './style.scss';
import { generateRandomColor } from '../../utils';
import { toggleShowContactModal } from '../../redux/documentController/actions';
import { searchContact as getAllContact, getContact } from '../../api/contact';
import { setContacts, setLoadingContacts } from '../../redux/contact/actions';
import {
  setSigners,
  setSignerContact,
  setActiveSignerContact,
} from '../../redux/createTemplate/actions';
import { DragHandle } from './DragHandle';
import { connect } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import { RECIPIENT_COLOR } from '../SigningProcessV2/constants';

import { RECIPIENTS_TYPE, RECIPIENTS_TYPE_LABEL } from '../../static';

const SignersFormV2 = props => {
  const [selected, setSelected] = useState({});

  const getActiveName = (selected, options) => {
    return options.find(f => f.value === selected)?.name;
  };

  const getActiveEmail = (selected, options) => {
    return options.find(f => f.value === selected)?.email;
  };

  const onFullNameChange = (e, ind, type) => {
    const { onFullNameChange } = props;
    if (type === 'autocomplete') {
      const value = getActiveName(selected[ind], props.contacts);
      if (value !== e.target.value) {
        const newData = selected;
        delete newData[ind];
        setSelected(newData);
      }
      onFullNameChange(e.target.value, ind);
      return;
    }
    onFullNameChange(e.target.value, ind);
  };

  const onEmailChange = (e, ind) => {
    const { onEmailChange } = props;
    onEmailChange(e.target.value, ind);
  };

  const addSigner = () => {
    const { addSigner } = props;
    addSigner({
      role: uuidV4(),
      fullName: '',
      email: '',
      isContactNameTouched: false,
      type: RECIPIENTS_TYPE.SIGNER,
    });
  };

  const removeSigner = index => {
    const { removeSigner } = props;
    removeSigner(index);
    const updateColors = props.colors.current;
    updateColors.splice(index, 1);
    props.setColors(updateColors);
  };

  const onKeyPressHandler = e => {
    if (e.key === 'Enter') {
      props.onEnterKeyPress();
    }
  };

  const getColor = (index, recipientType) => {
    const updateColors = props.colors.current;
    if (index > updateColors.length - 1) {
      const newColor =
        recipientType === RECIPIENTS_TYPE.CC
          ? RECIPIENT_COLOR
          : generateRandomColor();
      updateColors.push(newColor);
      props.setColors(updateColors);
    } else {
      if (
        recipientType === RECIPIENTS_TYPE.CC &&
        updateColors[index] !== RECIPIENT_COLOR
      ) {
        updateColors[index] = RECIPIENT_COLOR;
        props.setColors(updateColors);
      }
    }

    return updateColors[index];
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (!props.signingOrder) {
      props.setOrdered(true);
    }
    const items = reorder(
      props.signers,
      result.source.index,
      result.destination.index,
    );

    if (
      props.signers[result.source.index]?.type === RECIPIENTS_TYPE.CC ||
      props.signers[result.destination.index]?.type === RECIPIENTS_TYPE.CC
    ) {
      const colors = reorder(
        props.colors.current,
        result.source.index,
        result.destination.index,
      );
      props.setColors(colors);
    }

    props.setSigners(items);
  };

  const onChangeRecipientsType = (recipient, type, recipientIndex) => {
    const { signers } = props;
    const updateColors = props.colors.current;
    if (recipientIndex > updateColors.length - 1) {
      const newColor =
        type === RECIPIENTS_TYPE.CC ? RECIPIENT_COLOR : generateRandomColor();
      updateColors.push(newColor);
    } else {
      updateColors[recipientIndex] =
        type === RECIPIENTS_TYPE.CC ? RECIPIENT_COLOR : generateRandomColor();
    }
    props.setColors(updateColors);
    const updated = signers.map(signer => {
      if (signer.role === recipient.role) {
        return { ...signer, type };
      }
      return signer;
    });
    props.setSigners(updated);
  };

  const getItemStyle = (isDragging, draggableStyle, idx) => ({
    userSelect: 'none',
    boxShadow: isDragging ? '0px 5px 10px 2px rgba(0,0,0,0.1)' : 'none',
    // background: isDragging ? 'rgba(#f9fafc, .5)' : '#f9fafc',
    ...draggableStyle,
  });

  const getActiveContact = () => {
    if (props?.user) {
      const params = new URLSearchParams({
        order: 'desc',
        query: '',
        sortBy: 'createdAt',
      }).toString();

      getAllContact(params)
        .then(data => {
          const newContacts = data.contacts.map(c => {
            return {
              ...c,
              name: c.fullName,
              value: c.id,
            };
          });
          props.setLoadingContacts(false);
          props.setContacts(newContacts);
        })
        .catch(() => {
          props.setLoadingContacts(false);
          props.setContacts([]);
        });
    } else {
      props.setLoadingContacts(false);
    }
  };

  const handleEmailEvent = (e, ind, isBlur = false) => {
    const value = e.target.value;
    let shouldSetOrdered = false;
    if (isBlur) {
      const mailExist = props.signers.filter(a => a.email === value).length;
      shouldSetOrdered = mailExist > 1 && value;
    } else {
      const mailExist = props.signers.filter(a => a.email === value);
      shouldSetOrdered = mailExist.length > 0 && value === mailExist[0]?.email;
    }
    if (shouldSetOrdered) {
      props.setOrdered(true);
    }

    onEmailChange(e, ind);
  };

  const options = props?.contacts?.filter(
    t => !Object.values(selected).includes(t.value),
  );
  const autoFillContacts =
    typeof props?.user?.settings?.contacts?.autoFillContacts !== 'undefined'
      ? !!props?.user?.settings?.contacts?.autoFillContacts
      : true;

  useEffect(() => {
    if (autoFillContacts) {
      getActiveContact();
    }
  }, [autoFillContacts]);

  useEffect(() => {
    if (
      props?.activeSignerContact &&
      props?.signerContact &&
      Object.keys(props?.signerContact).length > 0
    ) {
    }
  }, [props.activeSignerContact, props?.signerContact]);

  return (
    <>
      {props?.showErrorToggle && (
        <>
          <span className="esign-input__error-msg esign-input__error-msg-custom">
            <ErrorIcon />
            Cannot have duplicate recipients unless the signing order is toggled
            ON
          </span>
        </>
      )}
      <div className="signers-form-v2">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {props.signers.map((signer, ind) => {
                  const activeColor = getColor(ind, signer.type);
                  return (
                    <Draggable
                      key={signer.role}
                      draggableId={signer.role}
                      index={ind}
                      isDragDisabled={props.isNotarizing}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={cn('signers-form-v2__add-signer-block', {
                            'signers-form-v2__add-signer-block--error':
                              signer.isEmailTouched &&
                              !signer.isNameValid &&
                              signer.isNameTouched &&
                              !signer.isEmailValid,
                          })}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            ind,
                          )}
                        >
                          <div
                            className={
                              'signers-form-v2__add-signer-block--color-tag'
                            }
                            style={{
                              backgroundColor: activeColor,
                            }}
                          />

                          {props?.signingOrder && (
                            <span
                              className={'signers-order'}
                              style={{ borderColor: activeColor }}
                            >
                              {ind + 1}
                            </span>
                          )}

                          {!props.isNotarizing ? (
                            <span className="signers-form-v2__title">
                              {signer?.type === RECIPIENTS_TYPE.CC ? (
                                <CCIcon />
                              ) : (
                                <SignerIcon />
                              )}
                              {signer?.isMe ? (
                                <span
                                  className={
                                    'signers-form-v2__add-signer--type'
                                  }
                                >
                                  Signer <span>(Me)</span>
                                </span>
                              ) : (
                                <select
                                  tabIndex={-1}
                                  className={
                                    'signers-form-v2__add-signer--type selectable'
                                  }
                                  onChange={e =>
                                    onChangeRecipientsType(
                                      signer,
                                      e.target.value,
                                      ind,
                                    )
                                  }
                                >
                                  {Object.keys(RECIPIENTS_TYPE).map(key => (
                                    <option
                                      value={RECIPIENTS_TYPE[key]}
                                      key={key}
                                      selected={
                                        RECIPIENTS_TYPE[key] === signer?.type
                                      }
                                    >
                                      {
                                        RECIPIENTS_TYPE_LABEL[
                                          RECIPIENTS_TYPE[key]
                                        ]
                                      }
                                    </option>
                                  ))}
                                </select>
                              )}
                            </span>
                          ) : null}
                          <div
                            className={'signers-form-v2__add-signer--content'}
                          >
                            {!props.isNotarizing && (
                              <DragHandle {...provided.dragHandleProps} />
                            )}
                            <div
                              className={
                                'signers-form-v2__add-signer--form-group'
                              }
                            >
                              <div
                                className={`input-field-wrapper input-field-wrapper-name ${
                                  signer?.isMe ? `input-field-wrapper-me` : ``
                                }`}
                              >
                                {autoFillContacts && !signer?.isMe ? (
                                  <>
                                    <ContactInput
                                      type="text"
                                      placeholder={
                                        signer?.isMe
                                          ? 'Your full name'
                                          : signer.type === RECIPIENTS_TYPE.CC
                                          ? "CC's full name"
                                          : 'Signer’s full name'
                                      }
                                      autocomplete={
                                        signer?.isContactNameTouched && true
                                      }
                                      onFocus={() => {
                                        props.setSigners(
                                          props.signers.map(s =>
                                            s.role === signer.role
                                              ? {
                                                  ...s,
                                                  isContactNameTouched: true,
                                                }
                                              : { ...s },
                                          ),
                                        );
                                      }}
                                      selected={selected[ind]}
                                      onSelected={value => {
                                        setSelected(prev => ({
                                          ...prev,
                                          [ind]: value,
                                        }));
                                        const { onFullNameEmailChange } = props;
                                        const activeName = getActiveName(
                                          value,
                                          props.contacts,
                                        );
                                        const activeEmail = getActiveEmail(
                                          value,
                                          props.contacts,
                                        );
                                        onFullNameEmailChange(
                                          activeName,
                                          activeEmail,
                                          ind,
                                        );
                                      }}
                                      options={options}
                                      value={signer.fullName}
                                      onChange={e =>
                                        onFullNameChange(e, ind, 'autocomplete')
                                      }
                                      onBlur={e =>
                                        onFullNameChange(e, ind, 'autocomplete')
                                      }
                                      error={
                                        props.showError &&
                                        (!signer.isNameValid ||
                                          signer.fullName.length > 50)
                                      }
                                      errorMessage={
                                        signer.fullName.length > 50
                                          ? 'The name shouldn’t be longer than 50 characters.'
                                          : `Please enter ${
                                              signer?.isMe
                                                ? 'your'
                                                : "recipient's"
                                            } first and last name`
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <CustomInput
                                      value={signer.fullName}
                                      type="text"
                                      disabled={signer.isDisabled}
                                      autoFocus={
                                        props.signers.length - 1 === ind
                                      }
                                      placeholder={
                                        signer?.isMe
                                          ? 'Your full name'
                                          : signer.type === RECIPIENTS_TYPE.CC
                                          ? "CC's full name"
                                          : 'Signer’s full name'
                                      }
                                      name="fullName"
                                      onChange={e =>
                                        onFullNameChange(e, ind, 'static')
                                      }
                                      onBlur={e =>
                                        onFullNameChange(e, ind, 'static')
                                      }
                                      onKeyPress={onKeyPressHandler}
                                      error={
                                        props.showError &&
                                        (!signer.isNameValid ||
                                          signer.fullName.length > 50)
                                      }
                                      errorMessage={
                                        signer.fullName.length > 50
                                          ? 'The name shouldn’t be longer than 50 characters.'
                                          : `Please enter ${
                                              signer?.isMe
                                                ? 'your'
                                                : "recipient's"
                                            } first and last name`
                                      }
                                    />
                                  </>
                                )}
                                {props?.user && !signer?.isMe ? (
                                  <span
                                    onClick={() => {
                                      props.setSignerContact({
                                        ...signer,
                                      });
                                      props.setActiveSignerContact(true);
                                      // props.toggleNewTemplateModal(false);
                                      props.setSignersParent(props.signers);
                                      props.toggleShowContactModal(true);
                                      getActiveContact();
                                    }}
                                    className="contact-action"
                                  >
                                    <AddressIcon />
                                  </span>
                                ) : null}
                              </div>
                              <div className="input-field-wrapper">
                                <CustomInput
                                  value={signer.email}
                                  type="email"
                                  disabled={signer.isDisabled}
                                  placeholder={
                                    signer?.isMe
                                      ? 'Your email'
                                      : signer.type === RECIPIENTS_TYPE.CC
                                      ? "CC's email"
                                      : 'Signer’s email'
                                  }
                                  name="email"
                                  onChange={e => {
                                    handleEmailEvent(e, ind, false);
                                  }}
                                  onBlur={e => {
                                    handleEmailEvent(e, ind, true);
                                  }}
                                  onKeyPress={onKeyPressHandler}
                                  error={
                                    props.showError && !signer.isEmailValid
                                  }
                                  errorMessage="Please enter a valid email address"
                                />
                              </div>
                            </div>
                          </div>
                          {!signer.isDisabled && ind > 0 && (
                            <button
                              className="remove-signer-btn"
                              onClick={() => removeSigner(ind)}
                              tabIndex={-1}
                            >
                              <CloseIcon />
                            </button>
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div>
          <button className="add-signer-btn" onClick={addSigner} tabIndex={0}>
            <AddIcon />
            Add Recipient
          </button>
        </div>
      </div>
    </>
  );
};

SignersFormV2.propTypes = {
  signers: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
      isNameValid: PropTypes.bool,
      isEmailValid: PropTypes.bool,
      isEmailTouched: PropTypes.bool,
      isNameTouched: PropTypes.bool,
      isDisabled: PropTypes.bool,
      isMe: PropTypes.bool,
      type: PropTypes.string,
      order: PropTypes.number,
      role: PropTypes.string,
    }),
  ),
  signingOrder: PropTypes.bool,
  addSigner: PropTypes.func,
  setSigners: PropTypes.func,
  removeSigner: PropTypes.func,
  onFullNameChange: PropTypes.func,
  onEmailChange: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
  showErrorMessage: PropTypes.bool,
};

SignersFormV2.defaultProps = {
  signers: [],
  showErrorMessage: false,
};

const mapStateToProps = ({ authUser, createTemplate, contact }) => {
  const { user } = authUser;
  const { contacts } = contact;
  const { activeSignerContact, signerContact } = createTemplate;

  return {
    contacts,
    user,
    activeSignerContact,
    signerContact,
  };
};

const mapDispatchToProps = {
  // setRoles,
  toggleShowContactModal,
  setContacts,
  setLoadingContacts,
  setSignerContact,
  setActiveSignerContact,
  setSignersParent: setSigners,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignersFormV2);
