import {
  setAuthorization,
  setCookie,
  getTokenFromCookie,
  setAuthorizationAdmin,
} from '../api';
import {
  getUserInfo,
  loginUser,
  loginUserByToken,
  loginUserV2,
} from '../api/auth';

import {
  isLocalhost,
  registerValidSW,
  checkValidServiceWorker,
} from '../utils';

import {
  DOMAIN_URL,
  DROP_BOX_API_KEY,
  ONE_DRIVE_API_KEY,
  GOOGLE_DRIVE_API_KEY,
  GOOGLE_DRIVE_CLIENT_ID,
  GOOGLE_DRIVE_APP_ID,
} from '../static/config';

import { getDataSessionStorage } from '../utils';

import { NotificationManager } from '../components/Notifications';

// GET CURRENT USER INFO
export const service_getCurrentUserInfo = props => {
  const { setShowLoader, loginUserSuccess, history } = props;

  setShowLoader(true);
  const prevPath = history.location.state?.prevPath;
  getUserInfo().then(res => {
    if (res?.originalUser) {
      loginUserSuccess(res.originalUser);
      const token = getTokenFromCookie();
      const dataFromStorage = getDataSessionStorage(token, 'impersonate');
      if (res?.originalUser?.isSuperAdmin && !dataFromStorage?.impersonate) {
        setAuthorizationAdmin(token);
      }
      window.uipe('track', 'Lead', {
        keys: {
          email: res.originalUser.email,
        },
        lead: {
          fullName: res.originalUser.fullName,
        },
      });

      if (!res?.originalUser?.isEmailVerified) {
        history.push(`/confirm-email`);
      } else {
        if (
          window.location.pathname !== '/editor' &&
          window.location.pathname !== '/pricing' &&
          window.location.pathname !== '/settings/billing/plans/checkout'
        ) {
          if (prevPath) {
            history.push(prevPath);
          } else {
            history.push('/dashboard');
          }
        }
      }

      setShowLoader(false);
    }
  });
};

// LOGIN WITH EMAIL AND PASSWORD
export const service_loginWithEmailPassword = props => {
  const {
    email,
    password,
    setShowLoader,
    history,
    loginUserError,
    loginUserSuccess,
    setIs2FAModalOpen,
    recaptchaToken,
  } = props;

  setShowLoader(true);

  loginUserV2({
    email,
    password,
    recaptchaToken,
  }).then(res => {
    if (res?.success) {
      setAuthorization(res.token);

      if (res.refreshToken === null) {
        setShowLoader(false);

        setIs2FAModalOpen(true);
      } else {
        if (res?.message) {
          setShowLoader(false);

          loginUserError('Invalid email or password');
        } else {
          service_getCurrentUserInfo({
            setShowLoader,
            loginUserSuccess,
            history,
          });
        }
      }
    } else {
      setShowLoader(false);

      loginUserError('Invalid email or password');
    }
  });
};

// LOGIN WITH LOGIN TOKEN
export const service_loginWithLoginToken = props => {
  const {
    loginToken,
    setShowLoader,
    history,
    loginUserError,
    loginUserSuccess,
    setIs2FAModalOpen,
  } = props;

  setShowLoader(true);

  loginUserByToken({ loginToken }).then(res => {
    if (res?.success) {
      setAuthorization(res.token);

      if (res.refreshToken === null) {
        setShowLoader(false);

        setIs2FAModalOpen(true);
      } else {
        if (res?.message) {
          setShowLoader(false);

          loginUserError('Invalid email or password');
        } else {
          service_getCurrentUserInfo({
            setShowLoader,
            loginUserSuccess,
            history,
          });
        }
      }
    } else {
      setShowLoader(false);

      loginUserError('Invalid email or password');
    }
  });
};

// LOGIN WITH GOOGLE / FACEBOOK / APPLE
export const service_loginWithGoogleFacebookApple = props => {
  const {
    loginFunction,
    token,
    setShowLoader,
    history,
    loginUserError,
    loginUserSuccess,
    setIs2FAModalOpen,
  } = props;

  setShowLoader(true);

  loginFunction(token).then(res => {
    if (res?.success) {
      setAuthorization(res.token);

      if (res.refreshToken === null) {
        setShowLoader(false);

        setIs2FAModalOpen(true);
      } else {
        if (res?.message) {
          setShowLoader(false);

          loginUserError('Invalid email or password');
        } else {
          service_getCurrentUserInfo({
            setShowLoader,
            loginUserSuccess,
            history,
          });
        }
      }
    } else {
      if (res?.message) {
        NotificationManager.error(res?.message, '', 3000, null, null, 'filled');
      } else {
        NotificationManager.error(
          'Something went wrong. Please, try again later.',
          '',
          3000,
          null,
          null,
          'filled',
        );
      }

      setShowLoader(false);
    }
  });
};

// APPEND GOOGLE, DROPBOX AND ONEDRIVE CLOUDS LINKS
export const service_appendCloudsLinks = () => {
  if (!isDropBoxReady()) {
    const dropBoxScript = document.createElement('script');
    dropBoxScript.setAttribute(
      'src',
      'https://www.dropbox.com/static/api/2/dropins.js',
    );
    dropBoxScript.setAttribute('id', 'dropboxjs');
    dropBoxScript.setAttribute('data-app-key', DROP_BOX_API_KEY);
    document.head.appendChild(dropBoxScript);
  }

  if (!isGoogleDriveReady()) {
    const googleDriveScript = document.createElement('script');
    googleDriveScript.setAttribute('src', 'https://apis.google.com/js/api.js');
    // googleDriveScript.setAttribute("id", "googleDrive");
    // googleDriveScript.setAttribute("data-app-key", GOOGLE_DRIVE_API_KEY);
    document.head.appendChild(googleDriveScript);
  }

  if (!isOneDriveReady()) {
    const oneDriveScript = document.createElement('script');
    oneDriveScript.setAttribute('src', 'https://js.live.net/v7.2/OneDrive.js');
    // oneDriveScript.setAttribute("id", "oneDrive");
    // oneDriveScript.setAttribute("data-app-key", ONE_DRIVE_API_KEY);
    document.head.appendChild(oneDriveScript);
  }

  function isDropBoxReady() {
    return !!window.Dropbox;
  }

  function isGoogleDriveReady() {
    return !!window.gapi;
  }

  function isOneDriveReady() {
    return !!window.OneDrive;
  }
};

// GOOGLE FILE IMPORTER
export const service_googleDriveDocumentsImpoter = (
  onUploadCallback,
  multiSelect,
) => {
  let oauthToken = null;

  let interval = setInterval(() => {
    if (window.gapi) {
      clearInterval(interval);

      window.gapi.load('auth', { callback: onAuthApiLoad });
      window.gapi.load('picker', { callback: () => {} });

      function onAuthApiLoad() {
        window.gapi.auth.authorize(
          {
            client_id: GOOGLE_DRIVE_CLIENT_ID,
            scope: ['https://www.googleapis.com/auth/drive.file'],
            immediate: false,
          },
          authResult => {
            if (authResult && !authResult.error) {
              // need pass object to trigger useEffect with oauthToken dependency
              oauthToken = { token: authResult.access_token };
              window.gapi.load('picker', { callback: onPickerApiLoad });
            }
          },
        );
      }

      function onPickerApiLoad() {
        if (oauthToken.token) {
          const { View, PickerBuilder } = window.google.picker;

          const docsView = new View(
            window.google.picker.ViewId.DOCS,
          ).setMimeTypes(
            'application/pdf,' +
              'application/msword,' +
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          );

          const foldersView = new View(
            window.google.picker.ViewId.FOLDERS,
          ).setMimeTypes(
            'application/pdf,' +
              'application/msword,' +
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          );

          const picker = new PickerBuilder()
            .setAppId(GOOGLE_DRIVE_APP_ID)
            .setOAuthToken(oauthToken.token)
            .addView(docsView)
            .addView(foldersView)
            .setDeveloperKey(GOOGLE_DRIVE_API_KEY)
            .setCallback(data => {
              if (data.action === window.google.picker.Action.PICKED) {
                const googleDriveFiles = data.docs.map(file => {
                  const downloadUrl = `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`;
                  return { url: downloadUrl, name: file.name };
                });

                // const [firstFile] = googleDriveFiles;
                // const {url, name} = firstFile;

                onUploadCallback(
                  googleDriveFiles,
                  'googleDrive',
                  oauthToken.token,
                );
              }
            });

          if (multiSelect) {
            picker.enableFeature(
              window.google.picker.Feature.MULTISELECT_ENABLED,
            );
          }

          const mountedPicker = picker.build();

          mountedPicker.setVisible(true);
        }
      }
    } else {
      service_appendCloudsLinks();
    }
  }, 100);
};

// DROP BOX FILE IMPORTER
export const service_dropBoxDocumentsImpoter = (
  onUploadCallback,
  multiselect,
) => {
  let interval = setInterval(() => {
    if (window.Dropbox) {
      clearInterval(interval);

      const options = {
        async success(files) {
          const dropBoxFiles = files.map(file => ({
            url: file.link,
            name: file.name,
          }));

          // const [firstFile] = dropBoxFiles;
          // const {url, name} = firstFile;

          onUploadCallback(dropBoxFiles);
        },
        cancel() {},
        linkType: 'direct',
        multiselect: multiselect,
        extensions: [
          '.pdf',
          '.doc',
          '.docx',
          '.txt',
          '.png',
          '.jpg',
          '.jpeg',
          '.ppt',
        ],
        folderselect: false,
        sizeLimit: 52428800, // 50MB in bytes
      };

      window.Dropbox.choose(options);
    } else {
      service_appendCloudsLinks();
    }
  }, 100);
};

// ONE DRIVE FILE IMPORTER
export const service_oneDriveDocumentsImpoter = (
  onUploadCallback,
  multiselect,
) => {
  let interval = setInterval(() => {
    if (window.OneDrive) {
      clearInterval(interval);

      const token = getTokenFromCookie();

      var options = {
        clientId: ONE_DRIVE_API_KEY,
        action: 'download',
        multiSelect: multiselect,
        openInNewWindow: true,
        advanced: {
          // endpointHint: "https://contoso.sharepoint.com/shared%20documents/",
          redirectUri: DOMAIN_URL,
          filter: '.pdf, .doc, .docx, .txt, .png, .jpg, .jpeg, .ppt',
        },
        success: function(data) {
          const { value: files } = data;
          const oneDriveFiles = files.map(file => {
            return {
              url: file['@microsoft.graph.downloadUrl'],
              name: file.name,
            };
          });

          setCookie(token);

          onUploadCallback(oneDriveFiles);
        },
        cancel: function() {
          setCookie(token);
        },
        error: function(err) {
          setCookie(token);
        },
      };

      window.OneDrive.open(options);
    } else {
      service_appendCloudsLinks();
    }
  }, 100);
};

//---------------------------------------------------------------------------------//

export function service_setEventDataLayerAnalytics(event, ecommerce) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ecommerce,
  });
}

//---------------------------------------------------------------------------------//

// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {});
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
