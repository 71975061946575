import React from 'react';

import { DOMAIN_URL, GOOGLE_AUTH_CLIENT_ID } from '../static/config';

import { ReactComponent as FolderSharedIcon } from '../assets/img/folder-shared-icon.svg';
import { ReactComponent as FolderIcon } from '../assets/img/folder-icon.svg';
import { ReactComponent as LockedDocIcon } from '../assets/img/locked-doc.svg';
import { ReactComponent as ActionRequiredIcon } from '../assets/img/warning.svg';
import { ReactComponent as WaitingIcon } from '../assets/img/clock-2.svg';
import { ReactComponent as SignatureCompletedIcon } from '../assets/img/signature-completed-icon.svg';
import { ReactComponent as NotarizeCompletedIcon } from '../assets/img/notarize-completed-icon.svg';
import { ReactComponent as NotAllowedIcon } from '../assets/img/not-allowed-2.svg';
import { ReactComponent as NotDetectedFileIcon } from '../assets/img/not-detected-file-icon.svg';
import { ReactComponent as DraftStatusIcon } from '../assets/img/draft-status_18x18.svg';
import { ReactComponent as TemplateLinkIcon } from '../assets/img/template_link_18x18.svg';
import { ReactComponent as TemplateStandardIcon } from '../assets/img/template_standard_18x18.svg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const arrayBufferToBase64 = buffer => {
  let binary = '';
  const bytes = [].slice.call(new Uint8Array(buffer));

  bytes.forEach(b => (binary += String.fromCharCode(b)));

  return window.btoa(binary);
};

export const validateEmail = email => {
  const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(email);
};

export const validateFullName = name => {
  // const re = /^[\p{L} ,.'-]*$/u;
  // return re.test(name);
  return !name?.includes('http://') && !name?.includes('https://');
};

export const getElementFileNameAndExtension = (element, isFolder) => {
  const { name } = element;

  if (isFolder) {
    return { name, extension: '' };
  }

  return {
    name: name.substring(0, name.lastIndexOf('.')),
    extension: `.${element.extension}`,
  };
};

export const convertStorageRemaining = ({ total, used }) => {
  const totalConverted = (total / (1024 * 1024 * 1024)).toFixed(1); // GB
  const usedConverted = (used / (1024 * 1024)).toFixed(1); // MB
  const remainingConverted = ((total - used) / (1024 * 1024)).toFixed(1); // MB
  return {
    total: totalConverted,
    used: usedConverted,
    remaining: remainingConverted,
  };
};

export const convertFileSize = fileSize => {
  if (fileSize > 1024 * 1024) {
    return `${Math.floor(fileSize / (1024 * 1024))} MB`;
  } else if (fileSize > 1024) {
    return `${Math.floor(fileSize / 1024)} KB`;
  } else if (fileSize < 1024) {
    return `${fileSize} KB`;
  }
  return fileSize;
};

export const debounce = (f, ms) => {
  let timer = null;

  return function(...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(onComplete, ms);
  };
};

export const getFileExtensionHandler = element => {
  let fileExtension = element.extension;

  if (element.type === 'folder') {
    fileExtension = element.shared.length ? 'folder-shared' : 'folder';
  } else if (element.type === 'template' || element.type === 'template_link') {
    fileExtension = element.type;
  } else {
    if (
      element.signRequested ||
      element.notarizeRequested ||
      element.signatureRequest?.waitForMe ||
      element?.signatureRequest?.correctionNeeded
    ) {
      fileExtension = 'action-required';
    }

    if (
      element.hasOwnProperty('completedAt') &&
      element.notarizeTransactionType === 'esign'
    ) {
      fileExtension = 'signed';
    }

    if (
      element.hasOwnProperty('completedAt') &&
      element.notarizeTransactionType === 'notarize'
    ) {
      fileExtension = 'notarized';
    }

    if (
      (element.waitForOthersCompletedByMe ||
        (!element.signatureRequest?.waitForMe &&
          element.signatureRequest?.waitForOthers)) &&
      !element?.signatureRequest?.correctionNeeded
    ) {
      fileExtension = 'waiting-for-others';
    }

    if (element.voided) {
      fileExtension = 'voided';
    }

    if (element.requiresPurchase) {
      fileExtension = 'locked';
    }

    if (element?.signatureRequest?.draftSignatureRequestId) {
      fileExtension = 'draft';
    }
  }

  return fileExtension;
};

export const chooseIconByExtension = fileExtension => {
  let fileIcon = '';

  switch (fileExtension) {
    case 'folder-shared':
      fileIcon = <FolderSharedIcon />;
      break;
    case 'folder':
      fileIcon = <FolderIcon />;
      break;
    case 'locked':
      fileIcon = <LockedDocIcon />;
      break;
    case 'action-required':
      fileIcon = <ActionRequiredIcon />;
      break;
    case 'waiting-for-others':
      fileIcon = <WaitingIcon />;
      break;
    case 'voided':
      fileIcon = <NotAllowedIcon />;
      break;
    case 'signed':
      fileIcon = <SignatureCompletedIcon />;
      break;
    case 'notarized':
      fileIcon = <NotarizeCompletedIcon />;
      break;
    case 'draft':
      fileIcon = <DraftStatusIcon />;
      break;
    case 'pdf':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'doc':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'docx':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'ppt':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'xlsx':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'rtf':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'odt':
      fileIcon = <NotDetectedFileIcon />; // not support
      break;
    case 'txt':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'htm':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'xls':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'csv':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'xlsm':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'xml':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'png':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'jpg':
      fileIcon = <NotDetectedFileIcon />;
      break;
    case 'template':
      fileIcon = <TemplateStandardIcon />;
      break;
    case 'template_link':
      fileIcon = <TemplateLinkIcon />;
      break;
  }
  return fileIcon;
};

/**
 * @param {Array<*>} files
 * @return {Promise<Array<{file: *, hasPassword: boolean}>>}
 */
export function getFilesPasswordStatus(files = []) {
  // when call this function first time load pdfjs library
  // and save pdfjs at window for next calls
  if (window.PDF_JS_LIB) {
    return getFilesWithPasswordStatus(files);
  } else {
    return import('pdfjs-dist/build/pdf').then(module => {
      module.default.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${module.default.version}/pdf.worker.js`;
      window.PDF_JS_LIB = module.default;

      return getFilesWithPasswordStatus(files);
    });
  }

  function getFilesWithPasswordStatus(files) {
    const filesReaderPromises = [];

    files.forEach(file => {
      // check pdf-file for password requirements
      // to do this need try to read file
      if (file.type === 'application/pdf') {
        const fileReaderPromise = new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);

          reader.onload = async function() {
            try {
              const fileAsArray = new Uint8Array(reader.result);
              // if file has password next promise will be rejected
              // and go to catch block
              await window.PDF_JS_LIB.getDocument(fileAsArray).promise;
              resolve({ file, hasPassword: false });
            } catch (err) {
              if (err.code === 1 && err.message === 'No password given') {
                resolve({ file, hasPassword: true });
              }
            }
          };
        });
        filesReaderPromises.push(fileReaderPromise);
      } else {
        filesReaderPromises.push(Promise.resolve({ file, hasPassword: false }));
      }
    });

    return Promise.all(filesReaderPromises);
  }
}

export const verifyPDFPassword = (file, password) => {
  if (window.PDF_JS_LIB) {
    return openPDFWithPassword(file, password);
  } else {
    return import('pdfjs-dist/build/pdf').then(module => {
      module.default.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${module.default.version}/pdf.worker.js`;
      window.PDF_JS_LIB = module.default;

      return openPDFWithPassword(file, password);
    });
  }
};

const openPDFWithPassword = (file, password) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async function() {
      try {
        const fileAsArray = new Uint8Array(reader.result);
        await window.PDF_JS_LIB.getDocument({ data: fileAsArray, password })
          .promise;
        resolve(true);
      } catch {
        resolve(false);
      }
    };
  });
};

export const registerValidSW = (swUrl, config) => {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {});
};

export const checkValidServiceWorker = (swUrl, config) => {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {});
};

export const googleOneTapOptions = {
  client_id: GOOGLE_AUTH_CLIENT_ID, // required
  auto_select: false, // optional
  cancel_on_tap_outside: false, // optional
  context: 'signin', // optional
  state_cookie_domain: DOMAIN_URL, // optional
};

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

export function isMobileBrowser() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return (
    toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem)) ||
    isIpad()
  );
}

export function isMobileBrowserV2() {
  let check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check || isIpad();
}

export function isMobileSafari() {
  return !!window.navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
}

export function isIosDevice() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform);
}

/**
 * Had to introduce this check due to iPadOS, as Safari there now mimick's desktop, resulting in games using the wrong settings
 *
 * @returns {boolean}
 * @private
 */
export function isIpad() {
  const isIpad = navigator.userAgent.toLowerCase().indexOf('ipad') !== -1;

  if (
    !isIpad &&
    navigator.userAgent.match(/Mac/) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2
  ) {
    return true;
  }

  return isIpad;
}

export const PageDetectionMethod = {
  Dashboard: () => window.location.href.search('/dashboard') > 0,
  Documents: () => window.location.href.search('/documents') > 0,
  Preview: () => window.location.search?.includes('preview='),
  Inbox: () => window.location.href.search('/inbox') > 0,
  Sent: () => window.location.href.search('/sent') > 0,
  Templates: () => window.location.href.search('/templates') > 0,
  Shared: () => window.location.href.search('/shared') > 0,
  Starred: () => window.location.href.search('/starred') > 0,
  Trash: () => window.location.href.search('/trash') > 0,
  Settings: () => window.location.href.search('/settings') > 0,
  Pricing: () => window.location.href.search('/pricing') > 0,
  Editor: () => window.location.href.search('/editor') > 0,
  EditorLanding: () => window.location.href.search('/pdf-editor') > 0,
  ExpiredLink: () => window.location.href.search('/expired-link') > 0,
  VoidedLink: () => window.location.href.search('/voided-link') > 0,
  AfterSigning: () => window.location.href.search('/after-signing') > 0,
  AfterCreatedTemplated: () =>
    window.location.href.search('/after-created-template') > 0,
  Support: () => window.location.href.search('/support') > 0,
  TermsOfUse: () => window.location.href.search('/terms-of-use') > 0,
  CreateSignature: () => window.location.href.search('/create-signature') > 0,
  ShowSignature: () => window.location.href.search('/add-signature') > 0,
  SignUp: () => window.location.href.search('/sign-up') > 0,
  Login: () => window.location.href.search('/login') > 0,
  SigningRequest: () => window.location.href.search('/signing-request') > 0,
  PrepareForm: () => window.location.href.search('/prepare-form') > 0,
  SigningProcess: () => window.location.href.search('/signing-process') > 0,
  ReminderOff: () => window.location.href.search('/reminder-off') > 0,
  LicenseInvite: () => window.location.href.search('/license-invite') > 0,
};

const randomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export function generateRandomColor() {
  const h = randomInt(0, 360);
  const s = randomInt(42, 98);
  const l = randomInt(40, 90);
  return hslToHex(h, s, l);
}

function hslToHex(h, s, l) {
  const hDecimal = l / 100;
  const a = (s * Math.min(hDecimal, 1 - hDecimal)) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = hDecimal - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

    // Convert to Hex and prefix with "0" if required
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0');
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export const getInitials = name => {
  const initials = name?.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);
  return initials?.join('');
};

export const getBase64FromUrl = async url => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const redirectToAppStore = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('android') > -1) {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.esign.esign';
  } else if (
    userAgent.indexOf('iphone') > -1 ||
    userAgent.indexOf('ipad') > -1 ||
    userAgent.indexOf('ipod') > -1
  ) {
    window.location.href =
      'https://apps.apple.com/us/app/esign-app/id1513896514';
  }
};

export const checkExist = value => {
  let isValid;
  if (/^\s*$/.test(value)) {
    isValid = false;
  } else if (/^\d+$/.test(value)) {
    isValid = value > 0;
  } else if (/^\s+$/.test(value)) {
    isValid = value.length > 0;
  } else {
    isValid = !!value;
  }

  return isValid;
};

export const getDataSessionStorage = (userId, type) => {
  try {
    const sourceData = sessionStorage.getItem(type);
    if (!sourceData) return {};

    const data = JSON.parse(sourceData);
    if (!data) return {};

    const activeData = data.find(a => a.userId === userId);
    if (activeData) {
      return activeData;
    }
    return {};
  } catch (e) {
    return {};
  }
};

export function setDataToSessionStorage(obj, type) {
  const data = JSON.parse(sessionStorage.getItem(type)) ?? null;
  const existData =
    data === null ? false : data.find(p => p.userId === obj.userId);
  let theData = {};
  if (typeof existData === 'undefined') {
    const newData = data.concat(obj);
    theData = JSON.stringify(newData);
  } else if (!existData) {
    theData = JSON.stringify([obj]);
  } else if (existData) {
    const newData = data.map(a =>
      a.userId === obj.userId ? { ...a, ...obj } : { ...a },
    );
    theData = JSON.stringify(newData);
  }

  return sessionStorage.setItem(type, theData);
}

export const formatDate = (
  date,
  dateFormat = 'MM/DD/YYYY',
  userTimeZone = '',
) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  let format = 'MM/DD/YYYY @ hh:mm A';
  if (dateFormat === 'DD/MM/YYYY') {
    format = 'DD MMMM, YYYY hh:mm A';
  }
  if (dateFormat === 'YYYY/MM/DD') {
    format = 'YYYY MMMM DD, hh:mm A';
  }
  try {
    return dayjs(date)
      .tz(userTimeZone)
      .format(format);
  } catch {}
  return '';
};

export const serialize = function(obj) {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

export const dollar = number => {
  try {
    return number?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  } catch {
    return number;
  }
};

export const booleanParse = str => str?.toLowerCase() === 'true';

export const toFixed = (n, fixed) =>
  `${n}`.match(new RegExp(`^-?\\d+(?:\.\\d{0,${fixed}})?`))[0];

export const roundTo = (n, digits) => {
  let negative = false;
  if (digits === undefined) {
    digits = 0;
  }
  if (n < 0) {
    negative = true;
    n = n * -1;
  }
  const multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  n = (Math.round(n) / multiplicator).toFixed(digits);
  if (negative) {
    n = (n * -1).toFixed(digits);
  }
  return n;
};

export const getNextOrPreviousRole = (roles, email) => {
  // Filter roles by the target email
  const filteredRoles = roles.filter(role => role.email === email);

  // If all filtered roles have status "awaiting", return the first item
  const allAwaitingOrViewed = filteredRoles.every(
    role => role.status === 'awaiting' || role.status === 'viewed',
  );
  if (allAwaitingOrViewed) {
    return filteredRoles[0] || null;
  }

  // Find any completed role with a different email before this one
  const previousCompletedRole = roles.find(
    role => role.status === 'completed' && role.email !== email,
  );

  // If no previous completed role, return the first filtered role
  if (!previousCompletedRole) {
    return filteredRoles[0] || null;
  }

  // Return the first filtered role that appears after the previous completed role
  const nextRole = filteredRoles.find(
    role => roles.indexOf(role) > roles.indexOf(previousCompletedRole),
  );

  return nextRole || filteredRoles[0] || null;
};
